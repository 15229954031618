import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Social from "../components/social"
import css from './basic-page.css'

const BasicPageTemplate = ({
  data,
  location,
}) => {
  const post = data.markdownRemark
  const { frontmatter, excerpt, html } = post
  const { title, description, social, language } = frontmatter
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} locale={language}>
      <SEO
        title={title}
        description={description || excerpt}
        lang={language}
      />
      <div css={css}>
        <div  dangerouslySetInnerHTML={{ __html: html }}/>
        { social ? (<Social/>) : null }
      </div>
    </Layout>
  )
}

export default BasicPageTemplate

export const pageQuery = graphql`
  query BasicPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        social,
        description,
        language
      }
    }
  }
`
