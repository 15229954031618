import React from "react"
import useMetadata from "../utils/hooks/useMetadata"
import Icon, * as Icons from "../icons"
import Link from "./link"

const Social = ({
  itemsPerRow = 4,
  itemSize = 20
}) => {
  const { social = {}} = useMetadata() || {}
  const linksList = Object.entries(social)
    .filter(([social, link]) => link && Boolean(Icons[social]))
    .map(([social, link]) => (
      <Link key={social} to={link}>
        <Icon
          css={{
            margin: `0 ${itemSize}px ${itemSize}px 0`
          }}
          alt={social}
          icon={Icons[social]}
          height={itemSize}
        />
      </Link>
    ))
  const width = itemsPerRow === Infinity
    ? linksList.length * 20 * 2
    : itemsPerRow * 20 * 2
  
  
  return (
    <>
      <div
        css={{
          width
        }}
        children={linksList}
      />
    </>
  )
}

export default Social
