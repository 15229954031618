import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

const Metadata = () => {
  const [metadata, setMetadata] = useState(null)
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            description
            siteUrl
            trackingId
            social {
              twitter
              facebook
              instagram
              pinterest
              linkedin
              vimeo
              behance
              domestika
            }
          }
        }
      }
    `
  )

  if(!metadata){
    setMetadata(siteMetadata)
  }
  return metadata || {}
}

export default Metadata
